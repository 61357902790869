import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import {appDatabasePrimaryFunctions, base} from '../../base';
import '../../styles/css/AdminMain.css';
import {
    Toast,
    ErrorMessage,
    validUrl,
    convertTimeStampToHumanReadable,
    convertDateToUtc
} from '../utils/HelpfulFunction';
import {Modal} from "reactstrap";
import DatePicker from '../utils/DatePicker';

class SetUpPushNotifications extends Component {
    constructor(props) {
        super(props);
        const todaysDateObject = new Date();
        todaysDateObject.setDate(todaysDateObject.getDate()-30);
        this.state = {
            loading: true,
            campaignId: null,
            sent: 0,
            direct_open: 0,
            influenced_open: 0,
            reengaged: 0,
            sent_optins: 0,
            errors: 0,
            searchStart: todaysDateObject,
            searchEnd: new Date(),
            openRate: "0",
            campaignMessages: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        await this.getStats(this.state.searchStart, this.state.searchEnd);
        await this.getCampaignNotifications();
    }

    async getCampaignNotifications(){
        //only need ios notifications because in theory android should be a mirror image of it
        let urlIos = `https://api.batch.com/1.1/${process.env.REACT_APP_IOS_BATCH_ID}/campaigns/list`;
        let urlAndroid = `https://api.batch.com/1.1/${process.env.REACT_APP_ANDROID_BATCH_ID}/campaigns/list`;
        let viewCampaignUrlIos = `https://api.batch.com/1.1/${process.env.REACT_APP_IOS_BATCH_ID}/campaigns/`;
        let viewCampaignUrlAndroid = `https://api.batch.com/1.1/${process.env.REACT_APP_ANDROID_BATCH_ID}/campaigns/`;
        const getObject = {method: "GET",  headers:{'Content-Type': 'application/json','X-Authorization': process.env.REACT_APP_BATCH_API_KEY}}
        if(process.env.NODE_ENV === "production"){
            urlIos = urlIos + "?live=true";
            urlAndroid = urlAndroid + "?live=true";
        }
        let responseIos;
        let responseAndroid;
        try {
            // responseIos = await fetch(urlIos, getObject)
            // responseAndroid = await fetch(urlAndroid, getObject)
            // const responseIosJson = await responseIos.json();
            // const responseAndroidJson = await responseAndroid.json();
            // let promiseArray = [];
            // if(responseIosJson && responseIosJson.length > 0){
            //     for(const i in responseIosJson){
            //         const campaignTokenIos = responseIosJson[i]['campaign_token'];
            //         const campaignTokenAndroid = responseAndroidJson[i]['campaign_token']
            //         promiseArray.push(fetch(viewCampaignUrlIos + campaignTokenIos, getObject));
            //         promiseArray.push(fetch(viewCampaignUrlAndroid + campaignTokenAndroid, getObject));
            //     }
            // }
            // if(promiseArray.length === 0) return;
            // const promises = await Promise.all(promiseArray);
            // const jsonPromisesArray = [];
            // for(const i in promises){
            //     jsonPromisesArray.push(promises[i].json())
            // }
            // const jsonPromises = await Promise.all(jsonPromisesArray);
            const scheduledPushesQueryResponse = await appDatabasePrimaryFunctions.ref('scheduledPushes').orderByChild('milliTime').startAfter(new Date().getTime()).limitToFirst(100).once('value');
            const newDetailsObject = {};
            scheduledPushesQueryResponse.forEach(function(data){
                const details = data.val();
                if(!newDetailsObject[details.overallPushId]){
                    newDetailsObject[details.overallPushId] = details;
                    let pushTime = details.pushTime || details.push_time;
                    newDetailsObject[details.overallPushId]['push_time_in_mil'] = new Date(pushTime).getTime();
                    if(details.type === "android"){
                        newDetailsObject[details.overallPushId]['android_campaign_token'] = details['campaign_token'] || details['campaignToken'];
                    } else {
                        newDetailsObject[details.overallPushId]['ios_campaign_token'] = details['campaign_token'] || details['campaignToken'];
                    }
                } else if(newDetailsObject[details.overallPushId] && details.type === "android") {
                    newDetailsObject[details.overallPushId]['android_campaign_token'] = details['campaign_token'] || details['campaignToken']
                } else if(newDetailsObject[details.overallPushId] && details.type === "ios") {
                    newDetailsObject[details.overallPushId]['ios_campaign_token'] = details['campaign_token'] || details['campaignToken']
                }
            })
            // for(const i in scheduledPushesQueryResponse){
            //     const details = scheduledPushesQueryResponse[i].val();
            //     if(!newDetailsObject[details.name]){
            //         newDetailsObject[details.name] = details;
            //         newDetailsObject[details.name]['push_time_in_mil'] = new Date(details.push_time).getTime();
            //     } else {
            //         newDetailsObject[details.name]['other_campaign_token'] = details['campaign_token']
            //     }
            // }
            let newValue = Object.values(newDetailsObject);
            // newValue.sort((a, b) => (a.push_time_in_mil > b.push_time_in_mil) ? 1 : -1)
            this.setState({campaignMessages: newValue});
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async searchData(){
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if(searchStart){
            searchStart = searchStart.getTime();
        }
        if(searchEnd){
            searchEnd = searchEnd.getTime();
        } else {
            searchEnd = new Date().getTime();
        }
        if(!searchStart){
            searchStart = searchEnd - (15778800000 - 86400000)
            this.setState({searchStart: new Date(searchStart)})
        } else if(searchStart < searchEnd - 15778800000){
            await ErrorMessage.fire({
                title: "Hold On!",
                text: 'Search start and end must be within 6 months!'
            })
            this.setState({
                loading: false
            })
            return;
        }
        this.setState({loading: true})
        await this.getStats(searchStart, searchEnd);
    }

    async getStats(start, end){
        const startDate = new Date(start).toISOString().replace(/T.*/,'');
        let endDate = '';
        if(end){
            endDate = new Date(end).toISOString().replace(/T.*/,'');
        }
        let androidUrlToGet, iosUrlToGet;
        let promiseArray = [];
        if(process.env.REACT_APP_ANDROID_BATCH_ID){
            androidUrlToGet = `https://api.batch.com/1.1/${process.env.REACT_APP_ANDROID_BATCH_ID}/transactional/stats/All?since=${startDate}&until=${endDate}`;
            promiseArray.push(fetch(androidUrlToGet, {method: "GET", headers:{'Content-Type': 'application/json','X-Authorization': process.env.REACT_APP_BATCH_API_KEY}}))
        }
        if(process.env.REACT_APP_IOS_BATCH_ID){
            iosUrlToGet = `https://api.batch.com/1.1/${process.env.REACT_APP_IOS_BATCH_ID}/transactional/stats/All?since=${startDate}&until=${endDate}`
            promiseArray.push(fetch(iosUrlToGet, {method: "GET", headers:{'Content-Type': 'application/json','X-Authorization': process.env.REACT_APP_BATCH_API_KEY}}))
        }
        if(!androidUrlToGet && !iosUrlToGet){
            this.setState({
                loading: false
            })
            return;
        }
        this.setState({loading: true});
        const promiseReturn = await Promise.all(promiseArray);
        const fetchedPushedTokens = await base.fetch('scheduledPushes', {
            context: this,
            asArray: true,
            queries: {
                orderByChild: 'milliTime',
                startAt: new Date(start).getTime(),
                endAt: new Date(end).getTime()
            }
        });
        const campaignPushObjects = [];
        for (const z in fetchedPushedTokens) {
            const pushedToken = fetchedPushedTokens[z];
            // Check if an object with the same `campaignToken` already exists in the array
            const isTokenPresent = campaignPushObjects.some(tokenObj => tokenObj.token === pushedToken.campaignToken);
            if (!isTokenPresent) {
                campaignPushObjects.push({ token: pushedToken.campaignToken, type: pushedToken.type });
            }
        }
        let sent = 0;
        let direct_open = 0;
        let influenced_open = 0;
        let reengaged = 0;
        let sent_optins = 0;
        let errors = 0;
        for(const i in promiseReturn){
            const jsonData = await promiseReturn[i].json();
            const dataArray = jsonData.detail;
            for(const t in dataArray){
                const data = dataArray[t];
                sent += data.sent || 0;
                sent_optins += data.sent_optins || 0;
                direct_open += data.direct_open || 0;
                influenced_open += data.influenced_open || 0;
                reengaged += data.reengaged || 0;
                errors += data.errors || 0;
            }
        }
        for(const t in campaignPushObjects){
            const campaignPushObject = campaignPushObjects[t];
            let batchPushApiKey;
            if(campaignPushObject.type === 'ios'){
                batchPushApiKey = process.env.REACT_APP_IOS_BATCH_ID;
            } else {
                batchPushApiKey = process.env.REACT_APP_ANDROID_BATCH_ID;
            }
            const urlCampaignStats = `https://api.batch.com/1.1/${batchPushApiKey}/campaigns/stats/${campaignPushObject.token}`
            const responseFromStats = await fetch(urlCampaignStats, {method: "GET", headers:{'Content-Type': 'application/json','X-Authorization': process.env.REACT_APP_BATCH_API_KEY}})
            const campaignStats = await responseFromStats.json();
            const dataArray = campaignStats.detail;
            for(const t in dataArray){
                const data = dataArray[t];
                sent += data.sent || 0;
                sent_optins += data.sent_optins || 0;
                direct_open += data.direct_open || 0;
                influenced_open += data.influenced_open || 0;
                reengaged += data.reengaged || 0;
                errors += data.errors || 0;
            }
        }
        let openRate = "0%"
        if(sent > 0){
            openRate = parseFloat(((direct_open + influenced_open) / sent)* 100).toFixed(2);
            openRate = openRate.toString() + "%";
            if(openRate === "100.00%"){
                openRate="100%"
            }
        }
        this.setState({
            sent: sent,
            direct_open: direct_open,
            influenced_open: influenced_open,
            reengaged: reengaged,
            sent_optins: sent_optins,
            errors: errors,
            openRate: openRate,
            loading: false
        })
    }

    async prepareNotification(){
        let {notificationLink, notificationTitle, notificationBody, notificationSendTime, schedulePush} = this.state;
        let errorMessage = "";
        if(!notificationBody || !notificationBody.trim()){
            errorMessage = 'The notification needs a message';
        } else if(!process.env.REACT_APP_IOS_BATCH_ID || !process.env.REACT_APP_ANDROID_BATCH_ID || !process.env.REACT_APP_BATCH_API_KEY){
            errorMessage = 'Your app is not set up for notifications, please contact Sqwad';
        } else if(schedulePush && !notificationSendTime){
            errorMessage = 'The notification is marked as scheduled but there is no scheduled time';
        } else if(schedulePush && notificationSendTime.getTime() <= Date.now()){
            errorMessage = 'The scheduled time is now or before now but it is marked as scheduled. Please choose a future time.';
        }
        if(errorMessage){
            await ErrorMessage.fire({
                title: "Hold On!",
                text: errorMessage
            })
            return;
        }
        if(!schedulePush){
            notificationSendTime = null;
        }
        this.setState({loading: true});
        let results;
        try {
            results = await base.fetch('batchId', {
                context: this,
                asArray: true
            })
        } catch (e) {
            console.log(e)
        }
        if(!results || results.length <= 0){
            await ErrorMessage.fire({
                title: "No recipients",
                text: 'No users available to send a notification to!'
            })
            this.setState({loading: false})
            return;
        }
        const allIdsSent = [];
        let nextIosIds = [];
        let nextAndroidIds = [];
        let nextIdsToSend = [];
        const overallPushId = appDatabasePrimaryFunctions.ref().push().key;
        for(let i in results){
            const resultIndividual = results[i];
            const pushId = resultIndividual.pushToken || resultIndividual;
            const device = resultIndividual.device;
            if(nextIdsToSend.length < 9999 && parseInt(i) + 1 !== results.length){
                if(allIdsSent.indexOf(pushId) === -1){
                    allIdsSent.push(pushId);
                    if(device === "iOS"){
                        nextIosIds.push(pushId);
                    } else if(device === "android"){
                        nextAndroidIds.push(pushId);
                    } else {
                        nextIosIds.push(pushId);
                        nextAndroidIds.push(pushId);
                    }
                    nextIdsToSend.push(pushId);
                }
            } else {
                if(parseInt(i) + 1 === results.length){
                    if(allIdsSent.indexOf(pushId) === -1){
                        allIdsSent.push(pushId);
                        if(device === "iOS"){
                            nextIosIds.push(pushId);
                        } else if(device === "android"){
                            nextAndroidIds.push(pushId);
                        } else {
                            nextIosIds.push(pushId);
                            nextAndroidIds.push(pushId);
                        }
                        nextIdsToSend.push(pushId);
                    }
                }
                if(nextIdsToSend.length > 0){
                    const campaignId = appDatabasePrimaryFunctions.ref().push().key;
                    if(nextIosIds.length > 0 || schedulePush){
                        await this.sendNotification(process.env.REACT_APP_IOS_BATCH_ID, notificationLink, notificationTitle, notificationBody, nextIosIds, schedulePush, notificationSendTime, campaignId, overallPushId);
                    }
                    if(nextAndroidIds.length > 0 || schedulePush){
                        await this.sendNotification(process.env.REACT_APP_ANDROID_BATCH_ID, notificationLink, notificationTitle, notificationBody, nextAndroidIds, schedulePush, notificationSendTime, campaignId, overallPushId);
                    }
                }
                nextIdsToSend = [];
                nextIosIds = [];
                nextAndroidIds = [];
            }
        }
        await this.getCampaignNotifications();
        this.setState({
            loading: false,
            modal: false
        })
        let messageToFire = "Notification Successfully Sent!";
        if(schedulePush){
            messageToFire = "Notification Successfully Scheduled!"
        }
        await Toast.fire({
            title: messageToFire
        })
    }

    async sendNotification(BATCH_API_KEY, notificationLink, notificationTitle, notificationBody, ids, schedulePush, notificationSendTime, campaignId, overallPushId){
        let urlToGetTransaction = `https://api.batch.com/1.0/${BATCH_API_KEY}/transactional/send`;
        let urlToGet = `https://api.batch.com/1.1/${BATCH_API_KEY}/campaigns/create`;
        let time = "now"
        let milliTime = new Date().getTime();
        if(notificationSendTime){
            time = convertDateToUtc(notificationSendTime).toISOString().split(".")[0];
            milliTime = notificationSendTime.getTime();
        }
        const jsonData = {};
        if(schedulePush){
            jsonData['name'] = "Campaign - " + campaignId + " - " + process.env.REACT_APP_FIREBASE_PROJECT_ID;
            jsonData['live'] = process.env.NODE_ENV === "production";
            jsonData['push_time'] = time.toString();
            jsonData['messages'] = [{
                "language": "en",
                "body": notificationBody,
            }];
            if(notificationTitle && notificationTitle.trim() !== ""){
                jsonData['messages'][0]['title'] = notificationTitle;
            }
            if(validUrl(notificationLink)){
                jsonData["deeplink"] = notificationLink;
            }
        } else {
            jsonData['group_id'] = "All";
            jsonData['recipients'] = {};
            jsonData['recipients']['tokens'] = ids;
            jsonData['sandbox'] = process.env.NODE_ENV !== "production";
            jsonData['message'] = {
                "body": notificationBody,
            };
            if(notificationTitle && notificationTitle.trim() !== ""){
                jsonData['message']['title'] = notificationTitle;
            }
            if(validUrl(notificationLink)){
                jsonData["deeplink"] = notificationLink;
            }
        }
        const postData = {body: JSON.stringify(jsonData), method: "POST",  headers:{'Content-Type': 'application/json','X-Authorization': process.env.REACT_APP_BATCH_API_KEY}};
        try {
            if(schedulePush){
                const schedulingResponse = await fetch(urlToGet, postData)
                if(schedulingResponse.ok){
                    const jsonResponse = await schedulingResponse.json();
                    let type = "ios";
                    if(process.env.REACT_APP_ANDROID_BATCH_ID === BATCH_API_KEY){
                        type = "android";
                    }
                    await this.saveDataInFirebase(jsonData, null, type, milliTime, jsonResponse, overallPushId)
                }
            } else {
                await fetch(urlToGetTransaction, postData)
            }
        } catch (e) {
            console.log(e);
            return false;
        }
        return true;
    }

    async saveDataInFirebase(jsonData, toEditToken, type, milliTime, jsonResponse, overallPushId){
         let campaignToken = toEditToken || jsonResponse.campaign_token;
         let postObject = {
             campaignToken: campaignToken,
             messages: jsonData.messages,
             pushTime: jsonData.push_time,
             milliTime: milliTime,
             name: jsonData.name,
             deepLink: jsonData.deeplink || "",
             type: type,
             overallPushId: overallPushId
         };
         await base.post(`scheduledPushes/${campaignToken}`, {data: postObject});
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            notificationLink: "",
            notificationTitle: "",
            notificationBody: "",
            editing: false,
            schedulePush: false,
            notificationSendTime: null,
            pushItemId: false,
            editingItem: null
        });
    }

    handleChange (evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    async deleteAllCampaignsByOverallPushId(item) {
        this.setState({loading: true});

        // Fetch all matching campaigns from Firebase
        const snapshot = await base.fetch('scheduledPushes', {
            context: this,
            asArray: true,
            queries: {
                orderByChild: 'overallPushId',
                equalTo: item.overallPushId
            }
        });

        // Collect promises for deleting each campaign
        const deletionPromises = snapshot.map(async campaign => {
            let urlToDelete;
            if(campaign.type === 'ios'){
                urlToDelete = `https://api.batch.com/1.1/${process.env.REACT_APP_IOS_BATCH_ID}/campaigns/delete/`;
            } else if(campaign.type === 'android'){
                urlToDelete = `https://api.batch.com/1.1/${process.env.REACT_APP_ANDROID_BATCH_ID}/campaigns/delete/`;
            } else return;
            const deleteObject = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': process.env.REACT_APP_BATCH_API_KEY
                }
            };

            let deleteCampaign = fetch(urlToDelete + campaign.campaignToken, deleteObject);

            let [result] = await Promise.all([deleteCampaign]);

            if (result.ok) {
                await base.remove(`scheduledPushes/${campaign.campaignToken}`);
            }

            return result.ok;
        });

        const results = await Promise.all(deletionPromises);
        await this.getCampaignNotifications();
        this.setState({loading: false});

        // Determine overall result to show appropriate message
        const success = results.some(result => result === true);
        if (success) {
            await Toast.fire({
                title: 'Successfully Deleted All Matching Campaigns'
            });
        } else {
            await ErrorMessage.fire({
                title: 'Failed To Delete Some Campaigns'
            });
        }
    }

    async editNotification(){
        this.setState({loading: true});
        // Fetch all matching campaigns from Firebase
        const scheduledPushesArray = await base.fetch('scheduledPushes', {
            context: this,
            asArray: true,
            queries: {
                orderByChild: 'overallPushId',
                equalTo: this.state.editingItem.overallPushId
            }
        });
        let {notificationLink, notificationTitle, notificationBody, notificationSendTime} = this.state;
        const time = convertDateToUtc(notificationSendTime).toISOString().split(".")[0];
        const milliTime = notificationSendTime.getTime();
        for(const i in scheduledPushesArray){
            const scheduledPush = scheduledPushesArray[i];
            const campaignToken = scheduledPush.campaignToken;
            let batchApiKey;
            if(scheduledPush.type === 'ios'){
                batchApiKey = process.env.REACT_APP_IOS_BATCH_ID;
            } else if(scheduledPush.type === 'android'){
                batchApiKey = process.env.REACT_APP_ANDROID_BATCH_ID;
            } else return;
            let urlToUpdate = `https://api.batch.com/1.1/${batchApiKey}/campaigns/update/`;
            const jsonData = {};
            jsonData['name'] = "Campaign - " + campaignToken + " - " + process.env.REACT_APP_FIREBASE_PROJECT_ID;
            jsonData['live'] = process.env.NODE_ENV === "production";
            jsonData['push_time'] = time.toString();
            jsonData['messages'] = [{
                "language": "en",
                "body": notificationBody,
            }];
            if(notificationTitle && notificationTitle.trim() !== ""){
                jsonData['messages'][0]['title'] = notificationTitle;
            }
            if(validUrl(notificationLink)){
                jsonData["deeplink"] = notificationLink;
            }
            const postData = {body: JSON.stringify(jsonData), method: "POST",  headers:{'Content-Type': 'application/json','X-Authorization': process.env.REACT_APP_BATCH_API_KEY}};
            const editingResponses = await Promise.all([fetch(urlToUpdate + campaignToken, postData)])
            if(editingResponses[0].ok){
                await this.saveDataInFirebase(jsonData, campaignToken, scheduledPush.type, milliTime, null, this.state.editingItem.overallPushId)
            }
        }
        this.setState({loading: false, modal:false});
        await Toast.fire({
            title: "Notification Successfully Edited!"
        })
    }

    editCampaign(item){
        let link;
        if(item.deeplink || item.deepLink){
            link = item.deeplink || item.deepLink;
        }
        const message = item.messages[0].body;
        let title;
        if(item.messages[0].title){
            title = item.messages[0].title;
        }
        let notificationPushTime;
        if(item.push_time || item.pushTime){
            notificationPushTime = new Date((item.push_time || item.pushTime) + "z");
        }
        this.setState({
            modal: true,
            notificationTitle: title,
            notificationBody: message,
            notificationLink:link,
            pushItemId: item.ios_campaign_token,
            notificationSendTime: notificationPushTime,
            schedulePush: true,
            editingItem: item,
            editing: true
        })
    }

    render() {
        const campaignMessages = this.state.campaignMessages || [];
        const isDev = process.env.REACT_APP_FIREBASE_PROJECT_ID === "anothertestdb-be6c5";
        const isFirebirds = process.env.REACT_APP_FIREBASE_PROJECT_ID === "coachella-firebirds-app";
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel" style={{marginTop: 20}}>
                <div className="col-md-12" style={{textAlign:'right'}}>
                    <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" style={{float:'right', marginRight:'5px'}}>Create Push Notification</button>
                </div>
                <form className="form-horizontal" style={{marginLeft: 20, marginBottom:10}}>
                    <div className="row g-3 align-items-center">
                        <div className="col-auto" style={{paddingLeft: 0}}>
                            <DatePicker isClearable selected={this.state.searchStart} onChange={date => this.setState({searchStart: date})}/>
                        </div>

                        <div className="col-auto">
                            <DatePicker isClearable selected={this.state.searchEnd} onChange={date => this.setState({searchEnd: date})}/>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-primary btn-admin" type="button" onClick={()=>this.searchData()}>Search</button>
                        </div>
                    </div>
                </form>
                <div className="admin-grid-container four-columns">
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-9">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{this.state.sent}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Sent</span>
                                    </div>
                                    {/*<div className="col-md-4" style={{fontSize:40, alignSelf:"center"}}>*/}
                                    {/*    <i className="fa fa-arrow-circle-o-down" aria-hidden="true" style={{color:"white", cursor: "pointer"}} onClick={()=> this.getUserData()}/>*/}
                                    {/*</div>*/}
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    {(isFirebirds || isDev) &&
                        <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                            <div className="card-body">
                                <blockquote className="card-bodyquote" style={{margin:0}}>
                                    <div className="row">
                                        <div className="col-md-9">
                                            <p style={{color:"white", fontSize:50, margin:0}}>{this.state.openRate}</p>
                                            <span style={{color:"#f8f8ff", fontSize:20}}>Open Rate</span>
                                        </div>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                    }
                    {/*<div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>*/}
                    {/*    <div className="card-body">*/}
                    {/*        <blockquote className="card-bodyquote" style={{margin:0}}>*/}
                    {/*            <div className="row">*/}
                    {/*                <div className="col-md-8">*/}
                    {/*                    <p style={{color:"white", fontSize:50, margin:0}}>{this.state.reengaged}</p>*/}
                    {/*                    <span style={{color:"#f8f8ff", fontSize:20}}>Re-Engaged</span>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </blockquote>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div style={{display: 'flex', flexDirection:'column', color: 'black', margin: 20, textAlign: "center", justifyContent: 'center', alignItems: 'center'}}>
                    {campaignMessages.length > 0 &&
                        <h3>
                            Scheduled Messages
                        </h3>
                    }
                    <div>
                        {campaignMessages.map(function(item,i){
                            let message = "";
                            let title = "";
                            let link = "";
                            let pushTime = item.push_time || item.pushTime;
                            for(const i in item.messages){
                                message = item.messages[i].body;
                                if(item.messages[i].title){
                                    title=item.messages[i].title
                                }
                                if(item.deeplink){
                                    link=item.deeplink || item.deepLink
                                }
                            }
                            return  <div style={{display: 'flex', margin: 20}} key={i}>
                                <div style={{border: "1px black dotted", padding: 10, minWidth: 400, textAlign: 'center'}}>
                                    <div>
                                        Push notification scheduled for:
                                    </div>
                                    <div>
                                        {convertTimeStampToHumanReadable(new Date(pushTime + 'z').getTime().toString())}
                                    </div>
                                    {title &&
                                        <div>
                                            Title: {title}
                                        </div>
                                    }
                                    <div>
                                       Message: {message}
                                    </div>
                                    {link &&
                                        <div>
                                            Link: {link}
                                        </div>
                                    }
                                    <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-around"}}>
                                        <button className="btn btn-info" onClick={() => this.editCampaign(item)}>Edit</button>
                                        <button className="btn btn-danger" onClick={() => this.deleteAllCampaignsByOverallPushId(item)}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        }, this)}
                    </div>
                </div>
            </div>
              <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
                  <div className="card">
                      <div className="card-body">
                          <h4 className="header-title mb-3"> Send Push Notification Form</h4>
                          <div id="rootwizard">
                              <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>
                                  <div className="tab-pane active show" id="first">
                                      <form id="accountForm" method="post" action="#" className="form-horizontal">
                                          <div className="row">
                                              <div className="col-12">
                                                  <div className="form-group row mb-3">
                                                      <label className="col-md-3 col-form-label" htmlFor="notificationTitle"> Title (optional)</label>
                                                      <div className="col-md-9">
                                                          <input id="notificationTitle" name="notificationTitle" type="text" className="form-control" value={this.state.notificationTitle} onChange={this.handleChange} placeholder="My First Push Notification" />
                                                      </div>
                                                  </div>
                                                  <div className="form-group row mb-3">
                                                      <label className="col-md-3 col-form-label" htmlFor="notificationBody"> Message</label>
                                                      <div className="col-md-9">
                                                          <textarea name="notificationBody" id="notificationBody" value={this.state.notificationBody} onChange={this.handleChange}/>
                                                      </div>
                                                  </div>
                                                  <div className="form-group row mb-3">
                                                      <label className="col-md-3 col-form-label" htmlFor="notificationLink"> Website To Link To (optional)</label>
                                                      <div className="col-md-9">
                                                          <input id="notificationLink" name="notificationLink" type="url" className="form-control" value={this.state.notificationLink} onChange={this.handleChange} placeholder="https://yournotificationlink.com" />
                                                      </div>
                                                  </div>
                                                  <div className="form-check">
                                                      <input value={this.state.schedulePush} className="form-check-input" id="schedulePush" name="schedulePush" type="checkbox" checked={this.state.schedulePush} onChange={this.handleChange} />
                                                      <label className="form-check-label" htmlFor="allElsePrize">Schedule Push</label>
                                                  </div>
                                                  {this.state.schedulePush &&
                                                      <div className="row g-3 align-items-center" style={{marginBottom: 20, marginTop: 10, marginLeft: 20}}>
                                                          <div className="col-auto" style={{paddingLeft: 0}}>
                                                              <DatePicker minWidth={250} showTimeInput dateFormat="Pp" isClearable selected={this.state.notificationSendTime} onChange={date => this.setState({notificationSendTime: date})}/>
                                                          </div>
                                                      </div>
                                                  }
                                              </div>
                                          </div>
                                      </form>
                                  </div>
                                  <ul className="list-inline wizard mb-0" style={{marginTop: 20}}>
                                      <a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.state.editing ? this.editNotification() : this.prepareNotification()}>
                                          {this.state.schedulePush ? "Schedule Push" : "Send Notification"}
                                      </a>
                                  </ul>

                              </div>
                          </div>
                      </div>
                  </div>
              </Modal>
         </div>
        );
    }
}

export default SetUpPushNotifications;
